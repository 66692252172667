@font-face {
	font-family: "schauburg-bold";
	src: url(./Schauburg/CCSchauburg-Bold.otf) format("opentype");
	src: url(./Schauburg/CCSchauburg-Bold.eot);
	src: url(./Schauburg/CCSchauburg-Bold.eot#iefix) format("embedded-opentype"), url(./Schauburg/CCSchauburg-Bold.woff2) format("woff2"), url(./Schauburg/CCSchauburg-Bold.woff) format("woff"), url(./Schauburg/CCSchauburg-Bold.ttf) format("truetype");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "theinhardt";
	src: url(./Schauburg/CCTheinhardt-Normal.otf) format("opentype");
	src: url(./Schauburg/CCTheinhardt-Normal.eot);
	src: url(./Schauburg/CCTheinhardt-Normal.eot#iefix) format("embedded-opentype"), url(./Schauburg/CCTheinhardt-Normal.woff2) format("woff2"), url(./Schauburg/CCTheinhardt-Normal.woff) format("woff"), url(./Schauburg/CCTheinhardt-Normal.ttf) format("truetype");
	font-weight: normal;
	font-style: normal;
}

/* @font-face {
	font-family: "icons";
	src: url(./Schauburg/schauburg-icons.eot);
	src: url(./Schauburg/schauburg-icons.eot#iefix) format("embedded-opentype"), url(./Schauburg/schauburg-icons.woff) format("woff"), url(./Schauburg/schauburg-icons.ttf) format("truetype");
	font-weight: normal;
	font-style: normal;
} */