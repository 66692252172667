@tailwind base;
@tailwind components;
@tailwind utilities;

@import './assets/fonts/fonts.css';

/* @layer components {
	.sidepane-right {
		@apply "absolute w-96 h-full p-8 top-0 right-1 z-10";
	}
} */

body, #root {
  @apply bg-app-background;
}

.app-container {
  @apply text-app-text bg-app-background;
}

html, body, #root {
  @apply h-full leading-normal p-0 m-0;
}



body {
  @apply p-0 m-0 antialiased;
  @apply font-sans;
}

#root {
  isolation: isolate;
}

:focus {
  @apply outline-none;
}
